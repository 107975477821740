<template>
  <div class="mine page">
    <div class="page-bg"></div>
    <div class="wrapper">
      <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
        <div class="header">
          <van-nav-bar class="nav-bar">
            <template #right>
              <van-icon name="setting-o" @click="showSetting()" color="#fff" />
            </template>
          </van-nav-bar>
          <div class="user-wrapper" @click="doLogin()">
            <van-image round class="user_img" :src="this.userInfo.header_img">
              <template v-slot:loading>
                <van-loading type="spinner" />
              </template>
            </van-image>
            <div class="login-content">
              <p class="login-btn">{{ this.userInfo.username }}</p>
              <!-- <p class="login-label">{{ this.userInfo.ip }}</p> -->
              <p class="login-label">
                <img data-v-2230b3a8="" src="img/mine/level_vip.webp" width="40"
                  style="position: relative; top: 10px; margin-right: 2px;">
                VIP {{ this.userInfo.vip }}
              </p>
            </div>
          </div>
        </div>
        <div class="content">
          <div class="finance">
            <div class="finance-item" @click="doPay()">
              <van-icon class="icon" style="" name="peer-pay" />
              <span class="text">Nạp tiền</span>
            </div>
            <div class="line"></div>
            <div class="finance-item" @click="doWithdrawal()">
              <van-icon class="icon" name="idcard" />
              <span class="text">Rút tiền</span>
            </div>
          </div>
          <div v-if="this.userInfo.money" class="wallet">
            <div class="part-1 van-hairline--bottom">
              <p class="flex-1 font-20 font-primary-color">Ví của tôi</p>
              <span class="font-15 font-gray">Chi tiết</span>
              <van-icon class="font-gray" style="font-size: 28px" name="arrow" />
            </div>
            <div class="part-2">
              <p class="balance van-ellipsis">{{ formatPrice(this.userInfo.money) }}</p>
              <span class="font-18 font-gray">Số dư</span>
              <div class="refresh-btn" @click="refresh()">
                <van-icon name="replay" />
              </div>
            </div>
            <div class="part-2">
              <p class="balance van-ellipsis">{{ this.userInfo.credit }}</p>
              <span class="font-18 font-gray">Điểm tín nhiệm</span>
              <div class="refresh-btn" @click="refresh()">
                <van-icon name="replay" />
              </div>
            </div>
          </div>
          <div :style="{ marginTop: menu_top + 'px' }" class="menu">
            <div class="menu-item" @click="$router.push({ path: '/Personalreport' });">
              <van-image class="menu-item-icon" src="img/mine/baobiao.svg">
                <template v-slot:loading>
                  <van-loading type="spinner" />
                </template>
              </van-image>
              <span class="menu-item-label">Báo cáo cá nhân</span>
            </div>
            <div class="menu-item" @click="exit()">
              <van-image class="menu-item-icon" src="img/mine/mingxi.svg">
                <template v-slot:loading>
                  <van-loading type="spinner" />
                </template>
              </van-image>
              <span class="menu-item-label">Chi tiết tài khoản</span>
            </div>
            <div class="menu-item" @click="$router.push({ path: '/GameRecord' });">
              <van-image class="menu-item-icon" src="img/mine/youxi.svg">
                <template v-slot:loading>
                  <van-loading type="spinner" />
                </template>
              </van-image>
              <span class="menu-item-label">Lịch sử đánh giá</span>
            </div>
            <div class="menu-item" @click="$router.push({ path: '/Infomation' });">
              <van-image class="menu-item-icon" src="img/mine/user.svg">
                <template v-slot:loading>
                  <van-loading type="spinner" />
                </template>
              </van-image>
              <span class="menu-item-label">Trung tâm cá nhân</span>
            </div>
            <div class="menu-item" @click="toNotice()">
              <van-image class="menu-item-icon" src="img/mine/gonggao.svg">
                <template v-slot:loading>
                  <van-loading type="spinner" />
                </template>
              </van-image>
              <span class="menu-item-label">Thông báo</span>
            </div>
            <div class="menu-item" @click="toService()">
              <van-image class="menu-item-icon" src="img/mine/kefu_1.svg">
                <template v-slot:loading>
                  <van-loading type="spinner" />
                </template>
              </van-image>
              <span class="menu-item-label">Chăm sóc khách hàng</span>
            </div>
          </div>
        </div>
      </van-pull-refresh>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      userInfo: {},
      menu_top: 40,
      isLoading: false,
    };
  },
  methods: {
    formatPrice(value) {
        let val = (value/1).toFixed(0).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    },
    refresh() {
      this.isLoading = true;
      setTimeout(() => {
        this.isLoading = false;
        if (localStorage.getItem('token')) {
          this.$toast("Làm mới thành công");
        } else {
          this.$router.push({ path: '/Login' });
        }
      }, 500);
    },
    exit() {
      this.$toast("Vui lòng hoàn thành đơn nhiệm vụ trước khi vào");
    },
    showSetting() {
      if (localStorage.getItem('token')) {
        this.$router.push({ path: '/Setting' });
      } else {
        this.$router.push({ path: '/Login' });
      }
    },
    toNotice() {
      if (localStorage.getItem('token')) {
        this.$router.push({ path: '/Notice' });
      } else {
        this.$router.push({ path: '/Login' });
      }
    },
    onRefresh() {
      setTimeout(() => {
        this.isLoading = false;
        if (localStorage.getItem('token')) {
          this.getUserInfo();
          this.$toast("Làm mới thành công");
        } else {
          this.$router.push({ path: '/Login' });
        }
      }, 500);
    },
    doLogin() {
      if (localStorage.getItem('token')) {
        this.$router.push({ path: '/Infomation' });
      } else {
        this.$router.push({ path: '/Login' });
      }
    },
    doPay() {
      this.$router.push({ path: '/ServiceOnline' });
      // this.$toast("Vui lòng liên hệ dịch vụ khách hàng để nạp tiền");
      // return false;
    },
    doWithdrawal() {
      this.$http({
        method: 'get',
        url: 'user_get_bank'
      }).then(res => {
        if (res.data.is_bank) {
          this.$router.push("withdraw");
        } else {
          this.$router.push("Setbank");
          this.$toast.fail("Vui lòng thiết lập thẻ nhận tiền!");
        }
      });
    },
    toService() {
      if (this.$store.getters.getBaseInfo.iskefu == 1) {
        this.$router.push("ServiceOnline");
      } else {
        this.$toast.fail("Chức năng đã bị vô hiệu hóa!");
      }
    },
    getUserInfo() {
      this.$http({
        method: 'get',
        url: 'user_info'
      }).then(res => {
        if (res.code === 200) {
          this.userInfo = res.data;
          this.menu_top = 15;
          if (this.userInfo.status !== 1) {
            this.$toast("Tài khoản đã bị đăng xuất");
            localStorage.clear();
            this.$router.push({ path: '/Login' });
          }
        } else if (res.code === 401) {
          this.$toast(res.msg);
        }
      });
    },
  },
  created() {
    if (localStorage.getItem('token')) {
      this.getUserInfo();
    } else {
      this.userInfo.username = "Đăng nhập/Đăng ký";
      this.userInfo.ip = "Đăng nhập để trải nghiệm thêm dịch vụ!";
      this.userInfo.header_img = "img/mine/avatar.png";
    }
  }
};
</script>

<style scoped>
.page {
  height: 100vh;
}

.mine {
  position: relative;
  bottom: 10px;
  background: #f2f2f5;
}

.mine .wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.van-nav-bar__right {
  top: 10px;
}

.nav-bar {
  background: linear-gradient(90deg, #7e5678, #e6c3a1);
}

.mine .header {
  background: linear-gradient(90deg, #7e5678, #e6c3a1);
  padding-bottom: 50px;
}

::v-deep .van-nav-bar__content {
  height: 50px;
}

::v-deep .van-hairline--bottom::after {
  border-bottom-width: 0px;
}

.mine .header .van-nav-bar .van-icon {
  font-size: 20px;
}

.mine .header .user-wrapper {
  display: flex;
  align-items: center;
  margin: 0 25px 0 25px;

}

.mine .user_img {
  height: 60px;
  width: 60px;
}

::v-deep .van-loading__spinner {
  height: 50px;
  width: 50px;
}

::v-deep .van-image__error-icon {
  font-size: 70px;
}

.mine .header .user-wrapper .login-content {
  flex: 1;
  margin-left: 15px;
}

.mine .header .user-wrapper .login-content .login-btn {
  display: inline-block;
  font-size: 20px;
  line-height: 0;
  color: #fff;
}

.mine .header .user-wrapper .login-content .login-label {
  margin-top: 0;
  font-size: 22px;
  display: inline-block;
  margin-left: 20px;
  color: rgb(200, 157, 96);
  font-weight: bold;
}

.mine .page-bg {
  height: 100vh;
  background: linear-gradient(90deg, #7e5678, #e6c3a1);
  background: #f2f2f5;
}

.mine .content {
  position: relative;
  padding: 10px 20px 0;
  height: auto;
  background-color: #f2f2f5;
}

::v-deep .van-pull-refresh__track .van-pull-refresh__head * {
  color: #ffffff;
  font-size: 35px;
}

.mine .wrapper .content .finance {
  position: absolute;
  display: flex;
  align-items: center;
  top: -28px;
  left: 20px;
  right: 20px;
  height: 65px;
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0 1.5px 1px 0 #e4e4e7;
}

.mine .wrapper .content .finance .line {
  width: 3px;
  height: 40px;
  background-color: #ccc;
}

.mine .wrapper .content .finance .finance-item {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.mine .wrapper .content .finance .finance-item .text {
  margin-left: 10px;
  font-size: 15px;
  color: #000;
  font-weight: 500;
}

.mine .wrapper .content .finance .finance-item .icon {
  font-size: 20px;
}

.mine .wrapper .content .menu {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0 1.5px 1px 0 #e4e4e7;
}

.mine .wrapper .content .menu .menu-item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 50%;
  height: 70px;
}

.mine .wrapper .content .menu .menu-item .menu-item-label {
  font-size: 14px;
  color: #868686;
  font-weight: 500;
}

.mine .wrapper .content .menu .menu-item .menu-item-icon {
  margin: 15px;
  width: 40px;
  height: 40px;
  -o-object-fit: contain;
  object-fit: contain;
}

.mine .wrapper .content .wallet {
  margin-top: 50px;
  padding: 0 20px;
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0 1.5px 1px 0 #e4e4e7;
}

.mine .wrapper .content .wallet .part-1 {
  display: flex;
  align-items: center;
  height: 50px;
}

.mine .wrapper .content .wallet .font-primary-color {
  color: #000;
  font-size: 15px;
  margin: 20px 0 5px;
}

.font-gray {
  color: #868686;
}

.mine .wrapper .content .wallet .part-2 {
  display: flex;
  align-items: center;
  height: 80px;
}

.mine .wrapper .content .wallet .part-2 .balance {
  flex: 1;
  font-size: 28px;
  color: #7e5678;
  font-weight: 700;
}

.mine .wrapper .content .wallet .van-hairline--bottom::after {
  border-bottom-width: 3px;
}

.mine .wrapper .content .wallet .part-2 .refresh-btn {
  margin-left: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35px;
  height: 35px;
  font-size: 18px;
  border-radius: 50%;
  color: #fff;
  background-color: #e6c3a1;
}
</style>
